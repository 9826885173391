
            @import 'src/styles/mixins.scss';
        

@import '@styles/sassVariables';
.corner {
    background: #fff5f3 ;
    color: $antivirus-choice-red;
    width: 80px;
    height: 30px;
    p{
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
.strike {
    text-decoration: line-through;
    color: $antivirus-choice-gray-dark
}
.red{
    color: $antivirus-choice-red;
}
.gray{
    background-color: $antivirus-choice-gray-light;
}
@media (min-width: 1024px) {
    .corner {
        background: none;
        width: 60px;
        height: 60px;
        border-top: solid 30px #fff5f3;
        border-right: solid 30px #fff5f3;
        border-left: solid 30px transparent;
        border-bottom: solid 30px transparent;
        p{
            top: -20px;
            left: -5px;
            transform: translate(0%,0%);
            transform: rotate(45deg);
        }
    }
    .listing-item {
        &:hover {
            -webkit-box-shadow: 2px 2px 22px 1px rgba(0, 0, 0, 0.507);
            box-shadow: 2px 2px 22px 1px rgba(0, 0, 0, 0.356);
        }
    }
}
@media (max-width: 320px) {
    .corner {
        display: none;
    }
}
.listing-item {
    margin-left: -.5rem;
    margin-right: -.5rem;
    transition: all 350ms ease-in-out;
    &:hover{
        div{
            .button{
                &:before {
                    transform: skewX(-45deg) translateX(16em);
                    transition: all .6s ease-in-out;
                }
                img{
                    margin-left: 1rem;
                    transition: all 0.3s ease-in-out;
                }
            }
            .button-right{
                filter: brightness(90%);
                img{  
                    right: 10px;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .listing-item {
        margin-left: 0 ;
        margin-right: 0 ;
        border: 2px solid $antivirus-choice-gray-light;
        &:hover{ transform: scale(1.03);}
    }
} 
.button-right{
    color: white;
    background-color: $antivirus-choice-green;
    transition: all .3s ease;
    img{    
        transition: all .3s ease;
        position: absolute;
        right: 1rem;
        top: 12px; 
    }
}
.yellow{
    background-color: $antivirus-choice-yellow;
}
.button {
    overflow: hidden;
    color: white;
    background: linear-gradient(
        to right,
        $antivirus-choice-orange-light 0%,
        $antivirus-choice-orange-dark 100%,
    );

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
    }   
}